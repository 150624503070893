//var m = z;

// Ignore Above
import app from 'flarum/app';
import { extend } from 'flarum/extend';
import UserCard from 'flarum/components/UserCard';
import User from 'flarum/models/User';
import Model from 'flarum/Model';
import uid from './components/uid.js';
import IndexPage from 'flarum/components/IndexPage';
import ItemList from 'flarum/utils/ItemList';
import FieldSet from 'flarum/components/FieldSet';
import avatar from 'flarum/helpers/avatar';
import username from 'flarum/helpers/username';
import OnlineUsers from './components/OnlineUsers.js';

function orderByLastSeenTime(a, b) {
    if (a.lastSeenTime() > b.lastSeenTime())
        return -1;
    if (a.lastSeenTime() < b.lastSeenTime())
        return 1;
    return 0;
}

app.initializers.add('iraj/uid', () => {
        //EXTENSION: UID
        extend(UserCard.prototype, 'infoItems', function (items) {
                items.add('uid', uid.component());
        });
        // EXTENSION: Online Users
        // extend(IndexPage.prototype, 'sidebarItems', function(items) {
        //         items.add('onlineUsersLink', (<a class="Button" href="/online">View Online Users</a>));
        // });
        app.routes.flagrowDirectLinksLogin = {
          path: '/online',
          component: OnlineUsers.component(),
        };
        
});

 
