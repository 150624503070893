import { extend } from 'flarum/extend';
import IndexPage from 'flarum/components/IndexPage';
import ItemList from 'flarum/utils/ItemList';
import FieldSet from 'flarum/components/FieldSet';
import avatar from 'flarum/helpers/avatar';
import username from 'flarum/helpers/username';
import Component from 'flarum/Component';

function orderByLastSeenTime(a, b) {
    if (a.lastSeenTime() > b.lastSeenTime())
        return -1;
    if (a.lastSeenTime() < b.lastSeenTime())
        return 1;
    return 0;
}

export default class OnlineUsers extends Component {
        
  view() {
        let displayMax = 50;
        let users = app.store.all('users').filter(u => u.isOnline());
        let total = users.length;
        let displayUsers = users.sort(orderByLastSeenTime).slice(0, displayMax);
        
        OnlineUsers = new ItemList();
        
        let i = 1;
        displayUsers.forEach((user) => {
            OnlineUsers.add('onlineuser-' + i, 
                <a href={app.forum.attribute('baseUrl') + '/u/' + user.id()}>
                    {avatar(user, {className: 'OnlineUser-avatar'})}
                    {username(user, {className: 'OnlineUser-name'})}
                </a>
            );
            i += 1;
        });
        
        if (total > displayMax) {
            OnlineUsers.add('onlineuser-more',
                <p>
                    And {total - displayMax} more
                </p>
            );
        }
        
        return (
          FieldSet.component({
              label: 'Online Members\n(WORK IN PROGRESS)',
              className: 'OnlineUsers',
              children: OnlineUsers.toArray()
          })
        )
    
  };
};
