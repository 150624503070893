import Component from 'flarum/Component';

export default class uid extends Component {
  config(isInitialized) {
    if (isInitialized) return;
  }
  
  getUidGroup(id) {
    if(id <= 200) {
      return "(Super Early User)"
    }
    else {
      return ""
    }
  }
  
  view() {
    return (
      <li class="item-uid">Member ID #{JSON.parse(app.current.user.id())}</li>
    )
  }
}
